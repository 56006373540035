import React from 'react';
import SphereComponent from "../components/SphereComponent";
import {useBenchContext} from "../context/BenchContext";
import Loading from "./Loading";

const Camera: React.FunctionComponent = React.memo(() => {
    const {currentBench} = useBenchContext();

    if (currentBench == null) {
        return <Loading/>
    }

    var sign = "";
    return (
        <div>
            <div id="video" style={{width: "80%"}}>
                <SphereComponent src={ `${currentBench.configuration.streamingURL}/${currentBench.configuration.streamingKey}.flv${sign}` } />
            </div>
        </div>
    )
});

export default Camera;
