import * as React from 'react';
import {Input, Modal, Form, Icon} from "antd";
import {Locale, ToastActions} from "ias-lib";
import {useState} from "react";
import {LoginStore} from "ias-auth-web";
import Parameters from 'parameters';

type Props = {
    visible: boolean;
    user?: User;
    modalId: number;
    handleIsAdmin: () => void;
    onCancel?: () => void;
}

const AdminLogInModal: React.FunctionComponent<Props> = ({visible, user, onCancel, handleIsAdmin}) => {
    const [password, setPassword] = useState("");
    const userId = user && user.id;

    return (
        <Modal
            visible={visible}
            onCancel={onCancel}
            width={350}
            title={Locale.trans("admin.logIn")}
            onOk = { async () => {
                let url = Parameters.ApiUrl + 'parameters/adminPassword/check-password';
                let body = new FormData();
                body.append("json", password);
                let query: any = {
                    method: 'POST',
                    headers: { 'X-Auth-Token': LoginStore.getJwt() },
                    crossOrigin: true,
                    type: 'json',
                    contentType: 'application/json',
                    body
                };
                await fetch(url, query).then(async function (response) {
                    if (response.status === 201) {
                        url = Parameters.ApiUrl + 'users/' + userId + '/admin';
                        let query: any = {
                            method: 'POST',
                            headers: { 'X-Auth-Token': LoginStore.getJwt() },
                            crossOrigin: true,
                            type: 'json',
                            contentType: 'application/json',
                        };
                        await fetch(url, query).then(() => {
                            handleIsAdmin();
                            onCancel && onCancel();
                            ToastActions.createToastSuccess(Locale.trans("admin.connected"));
                        })
                        .catch(() => {
                            ToastActions.createToastError(Locale.trans("error"));
                        });
                    } else {
                        ToastActions.createToastError(Locale.trans("error"));
                    }
                })
            }}
        >
            <Form.Item label="Mot de passe administrateur" hasFeedback>
                <Input.Password
                    onChange={(e)=>setPassword(e.target.value)}
                    addonBefore={<Icon type="lock" />}
                    type="password"
                    placeholder={Locale.trans('login.password')}
                />
            </Form.Item>
        </Modal>
    );
}

export default AdminLogInModal;
