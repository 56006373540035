import * as React from 'react';
import {Input, Modal, Form, Button, Switch, Row} from "antd";
import {Locale, ModalHandler, ToastActions} from "ias-lib";
import {useEffect, useState} from "react";
import {gql} from "apollo-boost";
import {useMutation} from "@apollo/react-hooks";
import ChangePasswordModal from "./ChangePasswordModal";
import {useThemeHandler} from "../../services/ThemeHandler";
import {AuthContext} from "ias-auth-web";

type Props = {
    visible: boolean;
    user?: User;
    modalId: number;
    onCancel?: () => void;
    isNew: boolean;
    me?: User;
}

type Fields = {
    firstName: string,
    phone: string,
    lastName: string,
    email: string,
    plainPassword?: string,
}
const defaultFields: Fields = {
    firstName: '',
    phone: '',
    lastName: '',
    email: '',
    plainPassword: '',
};


const ADD_USER = gql`
  mutation addUser ($user: AddUserInput!) {
     addUser (input: $user) {
      id
      firstName
      lastName
      phone
      email
    }
  }
`;

const UPDATE_USER = gql`
  mutation updateUser ($user: UserInput!, $id: Int!) {
     updateUser (input: $user, id: $id) {
      id
      firstName
      lastName
      phone
      email
    }
  }
`;

const DELETE_USER = gql`
  mutation deleteUser ($id: Int!) {
     deleteUser (id: $id)
  }
`;
const {confirm} = Modal;

const UserModal: React.FunctionComponent<Props> = ({visible, user, isNew, onCancel, me}) => {
    const [updateUser] = useMutation(UPDATE_USER);
    const [addUser] = useMutation(ADD_USER);
    const [deleteUser] = useMutation(DELETE_USER);
    const [fields, setFields] = useState<Fields>({...defaultFields});
    const {isBlack, toggle} = useThemeHandler();

    useEffect(() => {
        if(!user || isNew) {
            setFields({...defaultFields})
        } else {
            setFields({
                // @ts-ignore
                    firstName: user.first_name || user.firstName,
                // @ts-ignore
                    phone: user.phone_number || user.phoneNumber || user.phone,
                // @ts-ignore
                    lastName: user.last_name || user.lastName,
                    email: user.email,
            });
        }
    }, [user, isNew]);
    return (
        <Modal
            visible={visible}
            onCancel={onCancel}
            width={750}
            title={Locale.trans(isNew? 'user.add.title' : 'user.view.title')}
            onOk={() => {
                if (isNew) {
                    addUser({
                        variables: {
                            user: fields
                        }
                    })
                        .then(() => {
                            ToastActions.createToastSuccess("Utilisateur ajouté.");
                            onCancel && onCancel();
                        })
                        .catch(() => {
                            ToastActions.createToastError("Une erreur est survenue.");
                        });
                } else {
                    updateUser({
                        variables: {
                            user: fields,
                            id: user && user.id,
                        }
                    })
                        .then(() => {
                            ToastActions.createToastSuccess("Utilisateur modifié.");
                            onCancel && onCancel();
                        })
                        .catch(() => {
                            ToastActions.createToastError("Une erreur est survenue.");
                        });
                }
            }}
        >
            <Form.Item label="Prénom" hasFeedback>
                <Input value={fields.firstName} onChange={(el) => {
                    const value = el.target.value;
                    setFields(fields => ({
                        ...fields,
                        firstName: value,
                    }))
                }} />
            </Form.Item>
            <Form.Item label="Nom" hasFeedback>
                <Input value={fields.lastName} onChange={(el) => {
                    const value = el.target.value;
                    setFields(fields => ({
                        ...fields,
                        lastName: value,
                    }))
                }} />
            </Form.Item>
            <Form.Item label="Téléphone" hasFeedback>
                <Input value={fields.phone} onChange={(el) => {
                    const value = el.target.value;
                    setFields(fields => ({
                        ...fields,
                        phone: value,
                    }))
                }} />
            </Form.Item>
            <Form.Item label="Mail" hasFeedback>
                <Input value={fields.email} onChange={(el) => {
                    const value = el.target.value;
                    setFields(fields => ({
                        ...fields,
                        email: value,
                    }))
                }} />
            </Form.Item>
            {!isNew && (<>
                <Row style={{marginBottom: 10}}>
                    <Button type="primary" onClick={() => {
                ModalHandler.open(ChangePasswordModal, {user});
            }}>Changer le password</Button>
                    {user && !me && (
                        <Button className="delete-button" type="danger" onClick={() => {
                            confirm({
                                cancelText: "Non",
                                okText: "Oui",
                                title: "Êtes-vous sûr de vouloir supprimer cet utilisateur?",
                                content: "Ceci ne peut être défait. Vous pourrez créer un nouvel utilisateur avec le mail précédemment utilisé.",
                                onOk: () => {
                                    if(!user) { return; }
                                    deleteUser({
                                        variables: {
                                            id: user.id
                                        }
                                    }).then(() => {
                                        ToastActions.createToastSuccess("Utilisateur Supprimé.");
                                        onCancel && onCancel();
                                    }).catch(() => {
                                        ToastActions.createToastError("Une erreur est survenue.");
                                    });
                                }
                            });
                        }}>Supprimer l'utilisateur</Button>
                    )}
                </Row>
            {user && me && user.id === me.id && (
                <Row>
                    <Switch
                        checked={isBlack}
                        checkedChildren={Locale.trans('theme.black')}
                        unCheckedChildren={Locale.trans('theme.white')}
                        onChange={() => toggle()}
                    />
                </Row>)}</>)}
            {isNew && (
                <Form.Item label="Mot de passe" hasFeedback>
                    <Input type="password" value={fields.plainPassword} onChange={(el) => {
                        const value = el.target.value;
                        setFields(fields => ({
                            ...fields,
                            plainPassword: value,
                        }))
                    }} />
                </Form.Item>
            )}
        </Modal>
    );
}
export default UserModal;
