import React, {ChangeEvent, useEffect, useState} from 'react';
import {Checkbox, Input, Modal, Row, Select, Form} from "antd";
import {Locale, ToastActions} from "ias-lib";
import gql from 'graphql-tag';
import {useMutation, useQuery} from '@apollo/react-hooks';
import Loading from "../Loading";

const UPDATE_CONFIGURATION = gql`
  mutation editAlarmConfiguration($id: Int!, $configuration: AlarmConfigurationInput!) {
    editAlarmConfiguration(id: $id, input: $configuration) {
        id
        message
        isAcknowledgeable
        alarmOnUp
        alerts {
        user
        type
      }
    }
  }
`;
const ADD_CONFIGURATION = gql`
  mutation createAlarmConfiguration($bench: Int!, $configuration: AlarmConfigurationInput!) {
    createAlarmConfiguration(bench: $bench, input: $configuration) {
        id
        message
        isAcknowledgeable
        alarmOnUp
        alerts{
        user
        type
      }
    }
  }
`;

const { TextArea } = Input;

type Props = {
    visible: boolean;
    alarm: AlarmConfiguration;
    modalId: number;
    onCancel?: () => void;
    currentBench: Bench,
    isAdmin: boolean
}

const USERS = gql`
  {
    users {
        id
        firstName
        lastName
        email
        phone
    }
  }
`;

type AlarmAlertTypeFields ={ type: string; user: string; };

const AlarmConfigurationModal: React.FunctionComponent<Props> = ({visible, alarm, onCancel,currentBench, isAdmin}) => {
    const { loading, error, data } = useQuery(USERS);
    const [fields, setFields] = useState<{
        num: number,
        alerts: AlarmAlertTypeFields[],
        message: string,
        isAcknowledgeable: boolean,
        alarmOnUp: boolean,
    }>({
        num: 0,
        alerts: [],
        message: "",
        isAcknowledgeable: false,
        alarmOnUp: false,
    });
    const [updateMutation] = useMutation(UPDATE_CONFIGURATION);
    const [addMutation] = useMutation(ADD_CONFIGURATION);
    useEffect(() => {
        setFields({...alarm});
    }, [alarm]);

    if(currentBench === null || loading ||error) {
        return (
            <Modal
                visible={visible}
                onCancel={onCancel}
                width={750}
                title={Locale.trans('alarm.configuration.title')}
                >
                <Loading/>
            </Modal>
        );
    }
    return (
        <Modal
            visible={visible}
            onCancel={onCancel}
            width={750}
            title={Locale.trans('alarm.configuration.title')}
            onOk={() => {
                if(alarm.id) {
                    updateMutation({
                        variables: {
                            id: alarm.id,
                            configuration: {
                                num: fields.num,
                                alerts: fields.alerts.map((alertItem: AlarmAlertTypeFields) => ({
                                    type: alertItem.type,
                                    user: alertItem.user,
                                })),
                                message: fields.message,
                                isAcknowledgeable: fields.isAcknowledgeable,
                                alarmOnUp: fields.alarmOnUp,
                            },
                    }})
                        .then(() => {
                            onCancel && onCancel();
                            ToastActions.createToastSuccess('Modifiée');
                        })
                        .catch(() => {
                            ToastActions.createToastError('Une erreur est survenue');
                        });
                } else {
                    addMutation({
                        variables: {
                            bench: currentBench.id,
                            configuration: {
                                num: fields.num,
                                alerts: fields.alerts,
                                message: fields.message,
                                isAcknowledgeable: fields.isAcknowledgeable,
                                alarmOnUp: fields.alarmOnUp,
                            },
                        }})
                        .then(() => {
                            onCancel && onCancel();
                            ToastActions.createToastSuccess('Créée');
                        })
                        .catch(() => {
                            ToastActions.createToastError('Une erreur est survenue');
                        });
                }
            }}
            okButtonProps={{
                disabled: !isAdmin
            }}
        >
            <Row>
                <Select
                    style={{width: 200}}
                    onChange={(value: string) => setFields({
                        ...fields, alerts: [...fields.alerts, {
                            type: value,
                            user: "default",
                        }]
                    })}
                    placeholder={Locale.trans('alarm.configuration.select_new_action')}
                >
                    {['call', 'sms', 'mail'].map(number => (
                        <Select.Option value={number} key={number}>{Locale.trans(`alarm.configuration.alert.type.${number}`)}</Select.Option>
                    ))}
                </Select>
            </Row>
            {fields && fields.alerts && fields.alerts.map((alert: AlarmAlertTypeFields, key: number) => (
                <Row>
                    <Checkbox checked={true} onChange={() => setFields({
                        ...fields, alerts: [
                            ...fields.alerts.slice(0, key),
                            ...fields.alerts.slice(key + 1)
                        ]
                    })}> {Locale.trans(`alarm.configuration.alert.type.${alert.type}`)}</Checkbox>
                    <Checkbox checked={alert.user === "default"} onChange={(value) => value && setFields({
                        ...fields, alerts: [
                            ...fields.alerts.slice(0, key),
                            {
                                type: alert.type,
                                user: "default",
                            },
                            ...fields.alerts.slice(key + 1)
                        ]
                    })}> {Locale.trans(`alarm.configuration.alert.default_user`)}</Checkbox>
                    <Select
                        onChange={(value: string) => setFields({
                            ...fields, alerts: [
                                ...fields.alerts.slice(0, key),
                                {
                                    type: alert.type,
                                    user: value,
                                },
                                ...fields.alerts.slice(key + 1)
                            ]
                        })}
                        style={{width: 200}}
                        value={alert.user === "default" ? undefined : alert.user}
                        placeholder={Locale.trans('alarm.configuration.alert.select_user')}>
                        {data.users.map((user: User) => (
                            <Select.Option value={user.id + ""} key={user.id}>
                                {user.firstName} {user.lastName}
                                {alert.type === "mail"  ? `(${user.email})`: ''}
                                {alert.type === "call"  ? `(${user.phone})`: ''}
                            </Select.Option>
                        ))}
                    </Select>
                </Row>
            ))}
            <Row>
                <Form.Item label={Locale.trans('alarm.configuration.message')}>
                    <TextArea
                        rows={4}
                        onChange={(event: ChangeEvent<HTMLTextAreaElement>) => setFields({...fields, message: event.target.value })}
                        value={fields && fields.message}
                    />
                </Form.Item>
            </Row>
            {/*<Row>*/}
            {/*    <Checkbox checked={fields.alarmOnUp} onChange={(event) => setFields({...fields, alarmOnUp: event.target.checked})}>*/}
            {/*        {Locale.trans("alarm.onUp")}*/}
            {/*    </Checkbox>*/}
            {/*</Row>*/}
            <Row>
                <Checkbox checked={fields.isAcknowledgeable} onChange={(event) => setFields({...fields, isAcknowledgeable: event.target.checked})}>
                    {Locale.trans("alarm.isAcknowledgeable")}
                </Checkbox>
            </Row>
        </Modal>
    );
}
export default AlarmConfigurationModal;
