import * as React from 'react';
import {Locale} from "ias-lib";
import AlarmParameterCard from "./Alarms/AlarmParameterCard";
import {gql} from "apollo-boost";
import { useQuery } from '@apollo/react-hooks';
import Loading from "./Loading";
import {useBenchContext} from "../context/BenchContext";

type Props = {
    bench: Bench,
}

const ALARMS_CONFIGURATION = gql`
  query alarmConfigurations ($id: Int!) {
    alarmConfigurations(benchId: $id) {
      id
      num
      message
      isAcknowledgeable
      alarmOnUp
      alerts {
        user
        type
      }
      bench {
        id
      }
    }
  }
`;

const AlarmeConfigurations: React.FunctionComponent<Props> = ({bench}) => {
    const { loading, error, data } = useQuery(ALARMS_CONFIGURATION, {
        variables: {
            id: bench.id,
        }
    });
    const {currentBench} = useBenchContext();

    if (!currentBench || loading || error) {
        return (
            <Loading/>
        );
    }
    const configuration = currentBench.configuration;
    const dumpConfiguration: AlarmConfiguration[] = data.alarmConfigurations || [];
    let configurationAlarms;
    if (dumpConfiguration.length < configuration.nbInputsTOR) {
        const fillup = new Array(configuration.nbInputsTOR - dumpConfiguration.length);
        fillup.fill({
            id: "",
            banc: "4432132498",
            num: null,
            alerts: [],
            message: "",
            isAcknowledgeable: false,
            alarmOnUp: false,
        });
        configurationAlarms = dumpConfiguration.concat(fillup);
        configurationAlarms = configurationAlarms.map((alarm: AlarmConfiguration, key: number) => ({
                ...alarm,
                num: alarm.num || (key + 1)
            })

        );
    } else {
        configurationAlarms = dumpConfiguration;
    }
    return (
        <div className={"alarms_part"}>
            <h3>{Locale.trans('alarm.parametrage')}</h3>
            {configurationAlarms.map((alarm: AlarmConfiguration, key: number) => (
                <AlarmParameterCard key={alarm.id || (key + 1)} alarm={alarm} updatable={true}/>
            ))}
        </div>
    );
}
export default AlarmeConfigurations;
