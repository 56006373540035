import React, {useEffect} from 'react';
import {Locale, ModalHandler, ParametersContainer} from "ias-lib";
import BaseUrlConstants from "./constants/BaseUrlConstants";
import {AuthProvider} from "ias-auth-web";
import {Layout} from "./Layout";

import frFR from "antd/es/locale/fr_FR";
import {ConfigProvider} from "antd";
import fr from 'trans/messages.fr';
import { ApolloProvider } from '@apollo/react-hooks';
import {client} from 'services/ApolloClient';

import "style/style.scss" ;
import {init as initTheme} from "./services/ThemeHandler";

ParametersContainer.set({
    BASE_URL: BaseUrlConstants.BASE_URL
});

function App() {
    useEffect(() => {
        Locale.addTranslation({fr});
        initTheme();
    }, []);

    return (
        <ApolloProvider client={client}>
            <ConfigProvider locale={frFR}>
                <AuthProvider
                    baseComponent={Layout}
                    logoSrc="images/logo.png"
                    backgroundImageSrc="images/background.jfif"
                    logoAlt={"Logo TN International"}
                />
                <ModalHandler.ModalElement />
            </ConfigProvider>
        </ApolloProvider>
    );
}

export default App;
