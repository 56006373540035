import React from 'react';
import {Table, Select } from "antd";
import {Locale, ToastActions} from "ias-lib";
import {gql} from "apollo-boost";
import {useQuery, useMutation} from "@apollo/react-hooks";
import Loading from "../Loading";

const BENCHES = gql`
  {
    benches {
      id
      name
      technicalName
      affectedTo {
        id
        firstName
        lastName
      }
    }
    users {
        id
        firstName
        lastName
    }
  }
`;

const UPDATE_BENCH = gql`
  mutation affectBench($id: Int!, $bench: BenchInput!) {
    affectBench(id: $id, input: $bench) {
        id
        name
        technicalName
        affectedTo {
            id
            firstName
            lastName
        }
    }
  }
`;

const BenchList: React.FunctionComponent = () => {
    const { loading, error, data } = useQuery(BENCHES);
    const [editBench] = useMutation(UPDATE_BENCH);
    if(error || loading) {
        return (
            <Loading/>
        );
    }

    function getUserNamesById(id:number) {
        let user = data.users.find((user:any) => user.id === id);
        return user.firstName + " " + user.lastName;
    }

    function getUserById(id: number) {
        let user:User = data.users.find((user:any) => user.id === id);

        return user;
    }

    const columns = [{
        title: Locale.trans('bench.name'),
        dataIndex: 'name',
        key: 'name',
    }, {
        title: Locale.trans('bench.affected_user'),
        key: 'user',
        render: (record:any) => {
            return (
                <Select
                    onChange={(value: any) => {
                        let user = getUserById(value);
                        editBench({
                            variables: {
                                id: record.id,
                                bench: {
                                    affectedTo: value
                                },
                            }
                        }).then(() => {
                                ToastActions.createToastSuccess(user.firstName + " " + user.lastName + ' affecté au banc "' + record.name + '"');
                            }).catch(() => {
                                ToastActions.createToastError(Locale.trans("error"));
                            });
                    }}
                    style={{width: 200}}
                    value={record.affectedTo ? getUserNamesById(record.affectedTo.id) : Locale.trans('user.select')}
                    placeholder={Locale.trans('user.select')}>
                    {data.users.map(((user:any) => (
                        <Select.Option value={user.id} key={user.id}>
                            {user.firstName} {user.lastName}
                        </Select.Option>
                    )))}
                </Select>
            )
        },
    }];

    return (
        <div className="alarms_part">
            <h2>{Locale.trans("bench.title")}</h2>
            <Table columns={columns} dataSource={data.benches} />
        </div>
    );
}
export default BenchList;
