import * as React from 'react';
import {Icon, Spin} from "antd";

type Props = {}

const Loading: React.FunctionComponent<Props> = () => {
    const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

    return (
        <Spin indicator={antIcon} />
    );
}
export default Loading;
