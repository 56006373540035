import * as React from 'react';
import {Table} from "antd";

import './Alarms/Alarms.css';
import {useHistory} from "react-router";
import {useBenchContext} from "../context/BenchContext";
import Loading from "./Loading";
import {useContext} from "react";
import AdminContext from "../context/AdminContext";
import {useQuery} from "@apollo/react-hooks";
import {gql} from "apollo-boost";
import {Locale} from "ias-lib";
import moment from "moment";

type configurationLog = {
    id: string,
    updatedAt: string,
    updatedBy: User,
}

type Props = {}

const ALARMS_LOGS = gql`
  query configurationChangeLogs ($id: Int!){
    configurationChangeLogs(benchId: $id) {
      id
      message
      updatedAt
      updatedBy {
          id
          firstName
          lastName
      }
    }
  }
`;
const ConfigurationChangeLog: React.FunctionComponent<Props> = () => {
    const {currentBench} = useBenchContext();
    const isAdmin:boolean = useContext(AdminContext);
    const history = useHistory();
    const { loading, error, data } = useQuery(ALARMS_LOGS, {
        variables: {
            id: currentBench && currentBench.id,
        },
        pollInterval: 1500,
    });
    if(!isAdmin) {
        history.push('/');
    }
    if(currentBench === null || loading || error) {
        return (
            <Loading/>
        );
    }
    const columns = [{
        title: Locale.trans('alarm.num_io'),
        dataIndex: 'updatedBy',
        key: 'updatedBy',
        render: (updatedBy: User) => updatedBy.firstName + ' ' + updatedBy.lastName,
        sorter: (a: configurationLog, b: configurationLog) => {
            const aName = a.updatedBy.firstName   + ' ' + a.updatedBy.lastName;
            const bName = b.updatedBy.firstName   + ' ' + b.updatedBy.lastName;
            if(aName > bName) return 1;
            if(aName < bName) return -1;
            return 0;
        },
        filters: data.configurationChangeLogs.reduce((total: string[], alarm: configurationLog) => {
            if (total.indexOf(alarm.updatedBy.firstName   + ' ' + alarm.updatedBy.lastName ) !== -1) {
                return total;
            }
            total.push(alarm.updatedBy.firstName   + ' ' + alarm.updatedBy.lastName );
            return total;
        }, []).map((num: string) => ({
            text: num,
            value: num
        })),
        onFilter: (value: string, record: configurationLog) => (record.updatedBy.firstName   + ' ' + record.updatedBy.lastName) === value,
    },{
        title: Locale.trans('alarm.date'),
        dataIndex: 'updatedAt',
        key: 'updatedAt',
        render: (date: string) => {
            return moment(date).fromNow();
        },
        sorter: (obj: configurationLog, objB: configurationLog) => {
            const mdate = moment(obj.updatedAt);
            const mdateB = moment(objB.updatedAt);
            if (mdate.isAfter(mdateB)) {
                return 1;
            }
            if(mdate.isBefore(mdateB)) {
                return -1;
            }
            return 0;
        },
        defaultSortOrder: "descend" as 'descend'
    }, {
        title: Locale.trans('alarm.message'),
        dataIndex: 'message',
        key: 'message',
    }];
    return (
        <div className={"alarms_part"}>
            <h3>{Locale.trans('alarm.title')}</h3>
            <Table
                dataSource={data.configurationChangeLogs}
                columns={columns}
                rowKey={(record: configurationLog) => "" + record.id}
                pagination={{
                    pageSize: 20
                }}
            >

            </Table>
        </div>
    );
}
export default ConfigurationChangeLog;
