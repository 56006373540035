import request from 'reqwest';
import when from 'when';
import {ParametersContainer, TokenContainer} from "ias-lib";

class StateBench {

    static getState(id: number|string) {
        return when(request({
            url: ParametersContainer.get('BASE_URL') + 'state/bench/' + id,
            method: 'GET',
            crossOrigin: true,
            type: 'json',
            contentType: 'application/json',
            headers: { 'X-Auth-Token': TokenContainer.get() },
            data: undefined as any,
        }));
    }
}
export {StateBench};
