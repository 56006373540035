import * as React from 'react';

import ScrollArea from 'react-scrollbar';
import { Layout } from 'antd';

/**
 * A layout side menu that can be collapsable.
 * Based on the AntD sider.
 */
type Props = {
    onCollapse: (arg: boolean) => void;
    collapsed: boolean;
    showMask: boolean;
}

const Sider: React.FunctionComponent<Props> = ({
    children, onCollapse, collapsed, showMask,
}) => {
    return (
        <React.Fragment>
            {showMask && !collapsed && (
                <div className="sider-mask" onClick={() => onCollapse(true)} />
            )}
            <Layout.Sider collapsible collapsed={collapsed} onCollapse={onCollapse}>
                <ScrollArea horizontal={false}>{children}</ScrollArea>
            </Layout.Sider>
        </React.Fragment>
    );
};

export default Sider;
