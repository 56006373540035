import * as React from 'react';
import {Button, Icon, Modal, Tooltip} from "antd";
import {Locale, ToastActions} from "ias-lib";
import moment from 'moment';
import {gql} from "apollo-boost";
import {useMutation} from "@apollo/react-hooks";

import './AlarmLogModal.css';

type Props = {
    visible: boolean;
    alarm: AlarmLog;
    modalId: number;
    onCancel?: () => void;
    user: User;
    currentBench: Bench;
    isAdmin: boolean;
}


const ACKNOWLEDGE = gql`
  mutation acknowledge($id: Int!) {
    acknowledge(id: $id) {
      id
      acknowledgedAt
      acknowledgedBy {
        id
      }
    }
  }
`;

let iconTypes = {
    call: "phone",
    sms: "message",
    mail: "mail",
    notification: "notification"
};
const AlarmEvents: React.FunctionComponent<{alarm: AlarmLog}>  = ({alarm}) => {
    let component: any|null = null;
    if (alarm.acknowledgedAt && alarm.disappearedAt) {
        if(moment(alarm.acknowledgedAt).isBefore(alarm.disappearedAt)) {
            component = (
                <>
                    <li>Acquittée: {moment(alarm.acknowledgedAt).format('DD/MM/YYYY HH:mm')}</li>
                    <li>Descente du défaut: {moment(alarm.disappearedAt).format('DD/MM/YYYY HH:mm')}</li>
                </>
            )
        } else {
            component = (
                <>
                    <li>Descente du défaut: {moment(alarm.disappearedAt).format('DD/MM/YYYY HH:mm')}</li>
                    <li>Acquittée: {moment(alarm.acknowledgedAt).format('DD/MM/YYYY HH:mm')}</li>
                </>
            )
        }

    } else if (alarm.acknowledgedAt) {
        component = (
            <li>Acquittée: {moment(alarm.acknowledgedAt).format('DD/MM/YYYY HH:mm')}</li>
        );
    } else if (alarm.disappearedAt) {
        component = (
            <li>Descente du défaut: {moment(alarm.disappearedAt).format('DD/MM/YYYY HH:mm')}</li>
        );
    }
    return (
        <>
            <h3>{'é'.toUpperCase() + 'vénements'}</h3>
            <ul>
                <li>Montée du défaut: {moment(alarm.createdAt).format('DD/MM/YYYY HH:mm')}</li>
                {component}
            </ul>
        </>
    )
}

const AlarmLogModal: React.FunctionComponent<Props> = ({visible, alarm, onCancel, user, currentBench, isAdmin}) => {
    const [acknowledge ] = useMutation(ACKNOWLEDGE);
    const isAffectedToMe = currentBench && currentBench.affectedTo && currentBench.affectedTo.id === user.id;

    return (
        <Modal
            className={alarm.state}
            visible={visible}
            onCancel={onCancel}
            width={750}
            title={Locale.trans('alarm.log.title', {NUM: alarm.num})}
            onOk={() => {
                onCancel && onCancel();
            }}
        >
            <p>
                {alarm.message}
            </p>
            <AlarmEvents alarm={alarm}/>
            <h3>{Locale.trans("alarm.notifs")}</h3>
            <ul>
                {alarm.notificationEvents.map((notificationEvent => {
                    const isFailed = notificationEvent?.extra?.cSt === "failed";
                    return (
                        <li>
                            <Icon type={iconTypes[notificationEvent.type]} />&nbsp;
                            {isFailed && (
                                <>
                                <Tooltip title={"Echec"}><Icon type="close-circle" style={{ fontWeight: "bold", color: '#C00' }}  /></Tooltip>
                                    &nbsp;
                                </>
                            )}
                            Notifié à : {notificationEvent.notifiedTo.firstName} {notificationEvent.notifiedTo.lastName}
                            ({moment(notificationEvent.date).format('DD/MM/YYYY HH:mm')})
                        </li>
                    )
                }))}
            </ul>
            {(isAdmin || isAffectedToMe) && alarm.isAcknowledgeable && !alarm.acknowledgedAt && (
                <Button
                    type="primary"
                    onClick={() => {
                        acknowledge({variables: {id: alarm.id}})
                            .then(() => {
                                onCancel && onCancel();
                            })
                            .catch(() => {
                                ToastActions.createToastError(Locale.trans("alarm.acknowledged.error"));
                            });
                    }}
                    style={{width: '100%'}}
                >
                    {Locale.trans("alarm.acknowledge")}
                </Button>
            )}
        </Modal>
    );
}
export default AlarmLogModal;
