import React from 'react';
import {Col, Row} from "antd";
import BenchList from "./Affectation/BenchList";

type Props = {}

const Settings: React.FunctionComponent<Props> = () => {
    return (
        <Row gutter={12} className={"alarms"}>
            <Col span={14}>
                <BenchList />
            </Col>
        </Row>
    );
}
export default Settings;
