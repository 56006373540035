import * as React from 'react';
import {Avatar, Button, Icon, Layout as LayoutBase, Popover, Tooltip} from "antd";
import {Locale, ModalHandler} from "ias-lib";
import AdminLogInModal from "./Administration/AdminLogInModal";
import {AuthContext, LoginActions} from "ias-auth-web";
import {useContext, useState} from "react";
import AdminContext from "../context/AdminContext";
import {useHistory} from "react-router";
import {useBenchContext} from "../context/BenchContext";
import useInterval from "../hooks/useInterval";
import {StateBench} from "../services/StateBench";
import moment from "moment";

import './Header.css';
import UserModal from "./Settings/UserModal";

type Props = {
    toggleCollapse: () => void;
    collapsed: boolean;
    updateAdminStatus: any;
    handleIsAdmin: any;
}

const Header: React.FunctionComponent<Props> = ({toggleCollapse, collapsed, updateAdminStatus, handleIsAdmin}) => {
    const {user} = AuthContext.useAuthContext();
    const isAdmin = useContext(AdminContext);
    const history = useHistory();
    const [state, setState] = useState<{state: boolean, last_update:any}>();

    const {currentBench} = useBenchContext();

    useInterval(() =>  {
        if(currentBench) {
            StateBench.getState(currentBench.id)
                .then((response) => {
                    if (!state || response.state !== state.state || state.last_update !== response.last_update) {
                        setState(response);
                    }
                })
        }
    },  5000);
    return (
        <LayoutBase.Header>
            <div
                className="sider-toggle-menu-btn"
                role="button"
                tabIndex={0}
                onClick={() => toggleCollapse}
                onKeyPress={() => toggleCollapse}
            >
                <Icon type={collapsed ? 'menu-unfold' : 'menu-fold'} />
            </div>
            {isAdmin && (
                <div className="admin-message">
                    {Locale.trans("admin.connected")}
                </div>
            )}
            <div className="welcome-message">
                {user && Locale.trans('welcome', { USER: user.firstName })}
            </div>
            <div className="bench" style={{ marginLeft: "5%" }}>
                {currentBench && (
                    <Tooltip
                        title={Locale.trans('bench.state.last_updated', {DATE: state ? moment(state.last_update).format('dd DD/MM HH:mm:ss') : 'inconnue'})}
                    >
                        <span className={`bench-state ${state && state.state}`} />
                        {currentBench.name}
                    </Tooltip>
                )}
            </div>
            {user && (
                <Popover
                    overlayClassName="layout-user-menu"
                    content={
                        <React.Fragment>
                            <Button icon="user" onClick={() => ModalHandler.open(UserModal, {user, isNew: false, me: user})}>
                                {Locale.trans('pages.profile')}
                            </Button>
                            {isAdmin ? (
                                <Button icon="SupervisorAccount" onClick={() => updateAdminStatus()}>
                                    {Locale.trans('admin.logOut')}
                                </Button>
                            ) : (
                                <Button icon="SupervisorAccount" onClick={() => { ModalHandler.open(AdminLogInModal, {user: user, handleIsAdmin: handleIsAdmin} )} }>
                                    {Locale.trans('admin.logIn')}
                                </Button>
                            )}
                            <Button icon="logout" onClick={() => {
                                isAdmin && updateAdminStatus();
                                LoginActions.logoutUser();
                                history.push('/login');
                            }}>
                                {Locale.trans('login.logOut.button')}
                            </Button>
                        </React.Fragment>
                    }
                    title={null}
                    placement="bottomRight"
                    trigger="click"
                >
                    <Avatar size="large" className="profile-btn">
                        {user.firstName.charAt(0).toUpperCase()}
                        {user.lastName.charAt(0).toUpperCase()}
                    </Avatar>
                </Popover>
            )}
        </LayoutBase.Header>
    );
}
export default Header;
