import React, { useContext } from 'react';
import {Icon} from "antd";
import {ModalHandler} from 'ias-lib';
import AdminContext from "../../context/AdminContext";

import './AlarmParameterCard.css';
import AlarmConfigurationModal from "./AlarmConfigurationModal";
import {useBenchContext} from "../../context/BenchContext";

type Props = {
    alarm: AlarmConfiguration;
    updatable?: boolean;
}
let iconTypes = {
    call: "phone",
    sms: "message",
    mail: "mail",
    notification: "notification"
};

const AlarmParameterCard: React.FunctionComponent<Props> = ({alarm, updatable = false}) => {
    const {currentBench} = useBenchContext();
    const isAdmin:boolean = useContext(AdminContext);
    return (
        <div
            className={`alarm_parameter_card ${updatable ? "updatable": ""}`}
            onClick={() => {
                if (!updatable) return;
                ModalHandler.open(AlarmConfigurationModal, {alarm: alarm, currentBench, isAdmin})
            }}
        >
            <span className="number">{alarm.num}</span>
            <p>{alarm.message}</p>
            <div className="alerts_container">
                {alarm.alerts.map((alert) => (
                    <span className="alert" key={alert.type}>
                        <Icon type={iconTypes[alert.type]} />
                    </span>
                ))}
            </div>
        </div>
    );
};
export default AlarmParameterCard;
