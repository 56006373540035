import * as React from 'react';
import { Layout as LayoutBase} from "antd";
import Sider from "./components/Sider";
import {useSidebar} from "./hooks/useSidebar";
import {Link,  Switch, Route} from "react-router-dom";
import { Locale,  ToastActions } from "ias-lib";
import Menu from "./Menu";
import Alarmes from "./views/Alarmes";
import {AuthContext} from "ias-auth-web";
import { useState, useEffect} from "react";
import AdminContext from "./context/AdminContext";
import Camera from "./views/Camera";
import Settings from "./views/Settings";
import {BenchContextElement} from "./context/BenchContext";
import BenchAffectation from "./views/BenchAffectation";
import {LoginStore} from "ias-auth-web";
import Header from "./views/Header";
import Parameters  from './parameters';

import './Layout.css';
import ConfigurationChangeLog from "./views/ConfigurationChangeLog";

export const Layout = () => {
    const {user} = AuthContext.useAuthContext();
    const {collapsed, toggleCollapse} = useSidebar();

    const userId = user && user.id;
    const smallLogoUrl = 'images/logo-orano.png';

    const [isAdmin, setIsAdmin] = useState(false);

    async function getAdminStatus() {
        const url = Parameters.ApiUrl + 'users/' + userId + '/is-admin';
        let query: any = {
            method: 'GET',
            headers: { 'X-Auth-Token': LoginStore.getJwt() },
            crossOrigin: true,
            type: 'json',
            contentType: 'application/json',
        };
        const res = await (await fetch(url, query)).json();
        setIsAdmin(res);
    }

    function handleIsAdmin() {
        setIsAdmin(!isAdmin);
    }
    const updateAdminStatus = async() => {
        const url = Parameters.ApiUrl + 'users/' + userId + '/admin';
        let query: any = {
            method: 'POST',
            headers: { 'X-Auth-Token': LoginStore.getJwt() },
            crossOrigin: true,
            type: 'json',
            contentType: 'application/json',
        };
        await fetch(url, query).then(() => {
            isAdmin && setIsAdmin(false);
            ToastActions.createToastSuccess(Locale.trans("admin.disconnected"));
        })
            .catch(() => {
                ToastActions.createToastError(Locale.trans("error"));
            });
    };

    useEffect(() => {
        getAdminStatus(); // It gets admin status a first, then, every 5 minutes.
        setInterval(async () => {
            getAdminStatus();
        }, 300000); // Don't forget to change this milliseconds value to test this behaviour
    }, []);


    return (
        <BenchContextElement>
        <AdminContext.Provider value={isAdmin}>
        <LayoutBase
            className={collapsed ? 'sidebar-collapsed' : ''}
            style={{ minHeight: '100vh' }}
        >
            <Sider
                collapsed={collapsed}
                onCollapse={() => toggleCollapse()}
                showMask={window.innerWidth <= 900}
            >
                <Link className="sider-home-link" to="/">
                    <div className="logo">
                        <img
                            className="logo-icon"
                            alt={Locale.trans('pages.home')}
                            src={smallLogoUrl}
                        />
                    </div>
                </Link>
                <Menu />
            </Sider>
            <LayoutBase>
                <Header
                    toggleCollapse={toggleCollapse}
                    collapsed={collapsed}
                    handleIsAdmin={handleIsAdmin}
                    updateAdminStatus={updateAdminStatus}
                />
                <LayoutBase.Content>
                    <Switch>
                        <Route exact path="/" component={Alarmes} />
                        <Route exact path="/camera" component={Camera} />
                        <Route exact path="/settings" component={Settings} />
                        <Route exact path="/bench-affectation" component={BenchAffectation} />
                        <Route exact path="/logs" component={ConfigurationChangeLog} />
                    </Switch>
                </LayoutBase.Content>
            </LayoutBase>
        </LayoutBase>
        </AdminContext.Provider>
        </BenchContextElement>
    )
};
