// Apollo
import { ApolloClient } from "apollo-boost";
import { InMemoryCache } from "apollo-cache-inmemory";
import { toIdValue } from "apollo-utilities";
import { setContext } from 'apollo-link-context';
import { createHttpLink  } from 'apollo-link-http';

import { onError } from 'apollo-link-error';
import {AuthContext} from "ias-auth-web";
import BaseUrlConstants from "../constants/BaseUrlConstants";
import {LoginStore} from "ias-auth-web";


let AUTH_TOKEN = null;
const LoginChanged = () => {
  updateAuthToken(LoginStore.getJwt());
};

LoginStore.addChangeListener(LoginChanged);


const cache = new InMemoryCache({
  cacheRedirects: {
    Query: {
      // See https://www.apollographql.com/docs/react/recipes/performance.html#cache-redirects
      // The idea is to link the "user" query with the "User" entity available in the cache.
      user: (_, { id }) =>
        toIdValue(cache.config.dataIdFromObject({ __typename: "User", id })),
    },
  },
});

const httpLink = createHttpLink({
  uri:  BaseUrlConstants.BASE_URL + 'graphql/',
});

// const uploadLink = new createUploadLink({
//   uri: Parameters.ApiUrl + 'graphql/'
// });

// const authMiddleware = new ApolloLink((operation, forward) => {
//   // add the authorization to the headers
//   operation.setContext({
//     headers: {
//       "X-Auth-Token": AUTH_TOKEN ? AUTH_TOKEN : null,
//     },
//   });
//
//   return forward(operation);
// });

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  // const token = localStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      "X-Auth-Token": AUTH_TOKEN ? AUTH_TOKEN : null,
    }
  }
});


const loggOutAfterware = onError(({ graphQLErrors, response }) => {
  if (! graphQLErrors) {
    return;
  }
  const error = graphQLErrors.filter(error => error.unauthenticated)[0];

  if (error) {
    AuthContext.logout();
  }
});

const client = new ApolloClient({
   link: loggOutAfterware.concat(authLink).concat(httpLink),
  cache
});

function updateAuthToken(authToken) {
  AUTH_TOKEN = authToken;
}
//AuthContext.addListener(updateAuthToken);

export { client, updateAuthToken };
