import * as React from 'react';
import {Button, Form, InputNumber } from "antd";
import {useEffect, useState} from "react";
import {gql} from "apollo-boost";
import {useMutation} from "@apollo/react-hooks";
import {ToastActions} from "ias-lib";

type Props = {
    bench: Bench
}

const UPDATE_CONFIGURATION = gql`
  mutation updateBenchConfiguration($id: Int!, $configuration: BenchConfigurationInput!) {
    updateBenchConfiguration(benchId: $id, input: $configuration) {
        id
        configuration {
            errorThreshold
        }
    }
  }
`;
const SettingsTechnical: React.FunctionComponent<Props> = ({bench}) => {
    const [pollingFrequency, setPollingFrequency] = useState(0);
    const [errorThreshold, setErrorThreshold] = useState(0);
    const [update] = useMutation(UPDATE_CONFIGURATION);

    useEffect(() => {
        if (bench) {
            setPollingFrequency(bench.configuration.pollingFrequency);
            setErrorThreshold(bench.configuration.errorThreshold);
        } else {
            setPollingFrequency(0);
            setErrorThreshold(0);
        }
    }, [bench]);
    return (
        <div className="alarms_part">
            <h3>Paramètres</h3>
            {/*<Form.Item label="Fréquence de polling">*/}
            {/*    <InputNumber value={pollingFrequency} onChange={(value) => setPollingFrequency(value || 0)} />*/}
            {/*</Form.Item>*/}
            <Form.Item label="Seuil d'erreurs avant perte de communication">
                <InputNumber value={errorThreshold} onChange={(value) => setErrorThreshold(value || 0)} />
            </Form.Item>
            <Button type="primary" onClick={() => update({variables: {
                    id: bench.id,
                    configuration: {
                        errorThreshold
                    }
                }})
                .then(() => ToastActions.createToastSuccess('Modifié'))
                .catch(() => ToastActions.createToastError('Une erreur est survenue'))
            }>Enregistrer</Button>
        </div>
    );
}
export default SettingsTechnical;
