import React, { useContext } from 'react';
import {Locale} from "ias-lib";
import {Icon} from "antd";
import {default as MenuBase, ClickParam} from "antd/lib/menu";
import {useLocation, useHistory} from 'react-router-dom';
import AdminContext from "./context/AdminContext";

type Props = {

}

const Menu: React.FunctionComponent<Props> = () => {
    const location = useLocation();
    const history = useHistory();

    const selectedMenu = location.pathname;

    const handleMenuClick = (e: ClickParam) => {
        if (e.key !== location.pathname) {
            history.push(e.key);
        }
    };

    const isAdmin:boolean = useContext(AdminContext);

    return (
        <MenuBase
            theme="dark"
            onClick={handleMenuClick}
            selectedKeys={[selectedMenu]}
            mode="inline"
        >
            <MenuBase.Item key="/">
                <Icon type="home" />
                <span>{Locale.trans('pages.alarmes')}</span>
            </MenuBase.Item>
            <MenuBase.Item key="/camera">
                <Icon type="camera" />
                <span>{Locale.trans('pages.camera')}</span>
            </MenuBase.Item>
            {isAdmin && (
                <MenuBase.Item key="/settings">
                    <Icon type="setting" />
                    <span>{Locale.trans('pages.settings')}</span>
                </MenuBase.Item>
            )}
            {isAdmin && (
                <MenuBase.Item key="/bench-affectation">
                    <Icon type="user-add" />
                    <span>{Locale.trans('pages.affectation')}</span>
                </MenuBase.Item>
            )}
            {isAdmin && (
                <MenuBase.Item key="/logs">
                    <Icon type="file-text" />
                    <span>{Locale.trans('pages.logs')}</span>
                </MenuBase.Item>
            )}
        </MenuBase>
    )
};

export default Menu;
