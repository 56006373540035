import { useEffect, useRef } from 'react';

type Callback = any;

const useInterval = (callback: Callback, delay ?:number) => {
    const savedCallback = useRef<Callback>();

    useEffect(
        () => {
            savedCallback.current = callback;
        },
        [callback]
    );

    useEffect(
        () => {
            const handler = (...args: any[]) => savedCallback.current(...args);

            if (delay !== null) {
                const id = setInterval(handler, delay);
                return () => clearInterval(id);
            }
        },
        [delay]
    );
};

export default useInterval;
