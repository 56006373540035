import * as React from 'react';
import {Col, Row} from "antd";
import UserList from "./Settings/UserList";
import SettingsTechnical from "./Settings/SettingsTechnical";
import {useBenchContext} from "../context/BenchContext";
import Loading from "./Loading";

type Props = {}

const Settings: React.FunctionComponent<Props> = () => {
    const {currentBench} = useBenchContext();
    if(currentBench === null) {
        return (
            <Loading/>
        );
    }
    return (
        <Row gutter={12} className={"alarms"}>
            <Col span={14}>
                <UserList bench={currentBench} />
            </Col>
            <Col span={10}>
                <SettingsTechnical bench={currentBench}/>
            </Col>
        </Row>
    );
}
export default Settings;
