import * as React from 'react';
import {Button, Table} from "antd";
import {Locale, ModalHandler} from "ias-lib";
import UserModal from "./UserModal";
import {gql} from "apollo-boost";
import {useQuery} from "@apollo/react-hooks";
import Loading from "../Loading";

type Props = {
    bench: Bench
}

const USERS = gql`
  {
    users {
      id
      firstName
      lastName
      phone
      email
    }
  }
`;

const UserList: React.FunctionComponent<Props> = () => {
    const { loading, error, data } = useQuery(USERS);
    if (error || loading) {
        return (
            <Loading/>
        );
    }
    const columns = [{
        title: Locale.trans('user.name'),
        key: 'name',
        render: (record: User) => {
            return record.firstName + ' ' + record.lastName
        }
    }, {
        title: Locale.trans('user.mail'),
        dataIndex: 'email',
        key: 'email',
    }, {
        title: Locale.trans('user.phone'),
        dataIndex: 'phone',
        key: 'phone',
    }];

    return (
        <div className="alarms_part">
            <h3>{Locale.trans("user.title")}
            <Button
                style={{marginLeft: "2%"}}
                type="primary"
                onClick={() => {
                    ModalHandler.open(UserModal, {user: null, isNew: true})
                }}>{Locale.trans("user.add")}</Button></h3>
            <Table columns={columns} dataSource={data.users} onRow={(record: User, index: number) => ({
                onClick: () => {
                    ModalHandler.open(UserModal, {user: record, isNew: false})
                }
            })}/>
        </div>
    );
}
export default UserList;
